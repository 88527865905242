// deps
import { useIntl } from "react-intl";
import Head from "next/head";
import { Box, Button, useColorModeValue, VStack } from "@chakra-ui/react";
import NextLink from "next/link";

// components
import Splash from "@raiden/library-ui/components/Splash";
import PagePanel from "../components/PagePanel";

// libraries
import generateAdminPath from "@raiden/library-ui/libraries/utils/generateAdminPath";

export default function Page() {
  const intl = useIntl();

  return (
    <>
      <Head>
        <title>
          {intl.formatMessage({
            id: "raiden.admin.pages.NotFound.head.title",
            defaultMessage: "Page introuvable",
          })}
        </title>
      </Head>

      <PagePanel.Viewport
        h="full"
        bgColor={useColorModeValue("white", "gray.800")}>
        <VStack spacing="1rem">
          <Splash
            title={intl.formatMessage({
              id: "raiden.admin.pages.NotFound.texts.title",
              defaultMessage: "Erreur 404",
            })}
            description={intl.formatMessage({
              id: "raiden.admin.pages.NotFound.texts.description",
              defaultMessage:
                "Nous sommes désolés, la page que vous avez demandé n’existe pas ou n’est plus disponible à cette adresse.",
            })}
            image="error"
          />

          <Box>
            <NextLink
              href={generateAdminPath({ id: "dashboard" })}
              passHref={true}>
              <Button variant="solid" as="a">
                {intl.formatMessage({
                  id: "raiden.admin.pages.NotFound.action.home",
                  defaultMessage: "Revenir à l’accueil",
                })}
              </Button>
            </NextLink>
          </Box>
        </VStack>
      </PagePanel.Viewport>
    </>
  );
}
